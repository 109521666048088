<template >
  <div style="background: #fff;color: #000;height: 100%;" id="tomy"  v-cloak>
    <van-nav-bar title="工单详情" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
      </template>
    </van-nav-bar>

    <div class="hestou" style>

      <van-row class="cardiv">
        <van-col span="12" class="hui">
          车牌号
        </van-col>
        <van-col span="12" style="text-align: right;">
          {{ form.carNo }}
        </van-col>
      </van-row>
      <van-cell-group class="bot">
        <div class="cjh">
          <div class=" ldr">
            <span class="cnm hui">车架号</span>
            <span class="bar">{{ form.carVin }}</span>
          </div>
          <img class="che_img" style="margin-top: 3px;" @click="toimgdan(form.vinPic)" v-if="form.vinPic"
            :src="form.vinPic" alt="">
          <img class="che_img" style="margin-top: 3px;" v-else src="../../../assets/img/mendian.png" alt="">
        </div>
        <div class="cjh">
          <div class=" ldr">
            <span class="cnm hui">车型</span>
            <span class="bar">{{ form.carModel }}</span>
          </div>
        </div>
      </van-cell-group>

      <div class="bot" style="padding: 10px 16px; " v-if="yg">
        <div class="hui" style="margin-bottom: 4px;">车身照</div>
        <div class="cheshen" v-if="form.partPictures">
          <img class="cheimgs" @click="toimg(form.partPictures, idx)" :src="item.picUrl"
            v-for="(item, idx) in form.partPictures" :key="idx" alt="">

        </div>
        <div v-else>
          <img class="cheimgs" style="margin-top: 3px;" src="../../../assets/img/mendian.png" alt="" />
        </div>
      </div>
      <!-- 员工配件模式 -->
      <div class="pjmian" v-for="(item, idx) in sjlist" :key="idx" v-if="!yg">
        <div class="mian_a">
          <div>供应商：{{ item.supplierName }}</div>
          <!-- <div>供应商：询价汽配商</div> -->
        </div>
        <div class="mian_b">
          <div>联系人：{{ item.supplierLinkman }}</div>
          <div>手机号：{{ item.supplierPhone }}</div>
        </div>
        <div class="mian_c" v-for="(items, idxs) in item.list" :key="idxs">
          <div class="pimin">
            <span>{{ items.name }}</span>

            <div @click="setadd(items.data[0])">x{{ items.data[0].partCount }}</div>
          </div>
          <div class="pjdiv">
            <div class="" v-for="(it, is) in items.data" :key="is" v-if="it.price > 0">
              <div class="flix">
                <div>{{ it.classify }}：<span class="red">￥{{ it.price }}</span></div>
                <div><span v-if="it.status == 0 || it.status == 1" class="red"> (已下单)</span></div>
                <van-checkbox v-model="it.checked" @click="setcheckbox(items, it)" v-if="!items.disabled"
                  :disabled="items.disabled"></van-checkbox>
                <van-checkbox v-model="it.checked" v-else :disabled="items.disabled"></van-checkbox>
              </div>
              <div class="flxsxs" v-if="is == 1">
                <div class="bz">备注:<span>{{ it.des }}</span></div>

              </div>
            </div>
          </div>

        </div>
      </div>
 
    </div>


    <!--  -->
    <div style="height:100px"></div>
    <van-popup class="botsdb" v-model="showtan" position="bottom" :style="{ height: '45%' }">
      <div class="van-picker__toolbar boost">
        <button class="van-picker__cancel" @click="ppname = ''">清空</button>
        <input class="ipus" type="text" placeholder="请输入或选择" v-model="ppname" name="" id="">
        <button class="vanbor" @click="onConfirm">确认</button>
      </div>
      <van-picker title="标题" ref="checkbox" :columns="columns" @confirm="onConfirm" @cancel="showtan = false" />
    </van-popup>
    <van-popup class="jintan" v-model="xgshow">
      <div>修改 {{ pjform.partName }} 数量</div>
      <div class="flxx">
        <input class="ipos" type="number" v-model="pjform.partCount" />
      </div>
      <div class="flss">
        <van-button type="danger" size="small" @click="xgshow = false">取 消</van-button>
        <van-button type="primary" size="small" @click="setaddQd">提 交</van-button>
      </div>
    </van-popup>
    <van-button round class="botti" @click="setxiadan" type="danger" >下单</van-button>
    <div class="beijins" @click="shoufx = false" v-if="shoufx">
      <img src="../../../assets/img/wxfx.png" alt="">
    </div>
    <div class="zhe" @click="zheisok = false" v-if="zheisok"></div>
  </div>
</template>

<script>
import {
  getGarageInfo,
  checkAuth,
  orderCopyShareOrder,
  partcounts,
  config,
  purOrder,
  askPriceList,
  employeeBring,
  askPriceDerailPc,
  askPriceOfferPrice,
} from "@/api/check";
import { ImagePreview } from "vant";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      xgshow: false,
      ygshouw: false,
      ppname: "",
      afterData: [],
      sjlist: [],
      shoufx: false,
      pjform: {},
      askPricePartIds: [],
      supplierId: "",
      ids: null,
      dto: {},
      yg: true,
      id: "",
      gid: "",
      showtan: false,
      show: false,
      checked: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      orderData: {},
      form: {},
      // showPopover:'',
      showPopover: false,
      columns: [
        "下线",
        "副厂",
        "南吉",
        "原厂",
        "台湾",
        "品牌",
        "国产",
        "拆车",
        "精仿",
        "装车",
        "配套",
        "高仿",
      ],
      // 通过 actions 属性来定义菜单选项
      loading: false,
      pcisok: true,
      zheisok: true,
      value: "",
      isok: null,
      tanshow: false,
      total: 0,
      finished: false,
      name: "",
      heji: 0,
      pjisok: "true",
      payType: 1, //1微信支付 2储值卡支付
      offset: 0,
      certsList: [],
      active: "0",
      oid: this.$route.query.oid,
      gid: this.$route.query.gid,
      denglu: false,
      formName: {},
      windowHeight: document.documentElement.clientHeight - 130,
      page: 0,
      size: 20,
      idx: null,
      index: null,
      istr: true,
      pjitem: {},
    };
  },
  methods: {
    handleTestTasks(idx) {
      console.log(idx);
      console.log(this.$refs[`tab${idx}`]);
      this.$refs[`tab${idx}`][0].blur();
    },
    handleTestTaskss(idx) {
      if (this.ids == idx) {
        this.ids = null;
      } else {
        this.ids = idx;
      }

      // console.log(this.$refs[`tab${idx}`]);
      // this.pcisok = !this.pcisok;
      // this.$refs[`tab${idx}`][0].toggleMenu();
    },
    sercm() {
      this.zheisok = false;
    },
    setadd(item) {
      console.log(item);
      if (this.mengdian == 0) {
        return;
      }
      this.pjform = JSON.parse(JSON.stringify(item));
      this.xgshow = true;
    },
    dataFilter(val) {
      this.pjitem.classify = val;
      console.log(this.pjitem);
      console.log(val);
    },
    serinput(items, item) {
      items.classify = item
    },
    setaddQd() {
      var data = {
        askPriceId: this.pjform.askPriceId,
        partCount: this.pjform.partCount,
        partId: this.pjform.partId,
      };
      partcounts(data).then((e) => {
        if (e.code == 200) {
          this.onLoad();
          this.$toast.success("修改成功");
          this.xgshow = false;
        }
      });
    },
    setPname(idx, index) {
      this.ppname = "";
      this.idx = idx;
      this.index = index;
      this.showtan = true;
    },
    toimg(item, idx) {
      console.log(img);
      var img = [];
      for (var i in item) {
        img.push(item[i].picUrl);
      }
      // return
      this.$router.push({
        name: "showimg",
        query: {
          img: img.join(","),
          idx: idx,
        },
      });
    },
    toimgdan(img) {
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },
    onConfirm(e) {
      if (this.ppname != "") {
        this.afterData[this.idx].origin[this.index].classify = this.ppname;
        this.showtan = false;
      } else {
        setTimeout(() => {
          this.afterData[this.idx].origin[this.index].classify =
            this.$refs.checkbox.getValues()[0];
          this.showtan = false;
        }, 200);
      }

      // return

      // console.log(e)
    },
    settijiao() {
      var askPricePartList = [];
      for (var i in this.afterData) {
        for (var j in this.afterData[i].origin) {
          askPricePartList.push(this.afterData[i].origin[j]);
        }
      }
      console.log(askPricePartList);
      var data = {
        carModel: this.form.carModel,
        carNo: this.form.carNo,
        gid: this.form.gid,
        id: this.id,
        askPricePartList: askPricePartList,
      };
      askPriceOfferPrice(data).then((e) => {
        if (e.code == 200) {
          this.setemployeeBring();
          this.$toast.success("提交成功");
          setTimeout(() => {
            parent.WeixinJSBridge.call("closeWindow");
          }, 2000);
        }
      });
      // console.log(data);
    },
    cesi() {
      let that = this;
      var link =
        "https://shopping.car-posthouse.cn/purchase?gid=" +
        that.gid +
        "&oid=" +
        that.oid +
        "&supplierId=" +
        that.supplierId +
        "&carNo=" +
        that.form.carNo;
      // 共 // +this.askPricePartIds.length+'个'
      var title =
        that.form.garageName +
        "(" +
        that.form.carNo +
        ")" +
        "订单配件共" +
        this.askPricePartIds.length +
        "个";
      var datas = "【车型】宝马" + "【配件】啊大大 是试试";
      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: title,
          desc: datas,
          link: link,
          imgUrl:
            "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/999/1668159616590%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221111173951.jpg?Expires=1983519616&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=nQ%2BWj2EEpgRH%2FiusLlSCEXXrkjc%3D",
          success: function () { },
        });
      });
    },
    //下单
    setxiadan() {
      let that = this;
      if(this.askPricePartIds.length == 0){
        this.$toast("请先选择配件");
        return
      }
      purOrder({ askPricePartIds: this.askPricePartIds }).then((e) => {
        if (e.code == 200) {
          var link =
            "https://shopping.car-posthouse.cn/purchase?gid=" +
            that.gid +
            "&oid=" +
            that.oid +
            "&supplierId=" +
            that.supplierId +
            "&carNo=" +
            that.form.carNo;
          // 共 // +this.askPricePartIds.length+'个'
          var title =
            that.form.garageName + "(" + that.form.carNo + ")" + "订单配件";
          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: title,
              desc: that.form.carModel ? that.form.carModel : "",
              link: link,
              imgUrl:
                "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/999/1668159616590%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221111173951.jpg?Expires=1983519616&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=nQ%2BWj2EEpgRH%2FiusLlSCEXXrkjc%3D",
              success: function () { },
            });
          });
          this.askPricePartIds = [];
          this.$toast.success("提交成功");
          this.getshangjia();
          this.shoufx = true;
        }
      });
    },
    getConfig() {
      let that = this;
      let userAgent = navigator.userAgent;
      let shareUrl = "";
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        shareUrl = sessionStorage.getItem("originUrl");
      } else {
        shareUrl = location.href;
      }
      config({
        url: shareUrl,
        gid: that.gid,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData"],
          });
        } else {
          that.$toast(res.data.msg);
        }
      });
    },
    //绑定供应商
    setemployeeBring() {
      var data = {
        id: this.form.supplierId,
      };
      employeeBring(data).then((e) => {
        if (e.code == 200) {
        }
      });
    },
    tobendian() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      checkAuth({
        gid: this.gid,
      })
        .then((e) => {
          loading.clear();
          // console.log(e.data)
          if (e.data) {
            this.formName = e.data;
            this.yg = false;
            this.ygshouw = true;
            this.zheisok = false;
            this.getshangjia();
          }
        })
        .catch();
    },
    toXz() {
      this.tanshow = true;
      this.show = true;
    },

    //点击放大图片
    getImg(images) {
      ImagePreview([images]);
    },
    onLoad() {
      var that = this;
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      askPriceDerailPc({ id: this.id })
        .then((e) => {
          loading.clear();
          that.$nextTick(() => {
            that.getConfig();
          });
          that.tobendian();
          console.log(e);
          var list = e.data.askPricePartDTOS;
          this.form = e.data;
          let tempArr = [];

          for (let i = 0; i < list.length; i++) {
            if (tempArr.indexOf(list[i].partName) === -1) {
              var fom = {
                id: list[i].id,
                gid: list[i].gid,
                classify: list[i].classify
                  ? list[i].classify
                  : list[i].partType == 0
                    ? "原厂"
                    : "品牌",
                price: list[i].price,
                des: list[i].des,
              };
              that.afterData.push({
                name: list[i].partName,
                partPictures: list[i].partPictures,
                remark: list[i].remark,
                partCount: list[i].partCount,
                askPriceId: list[i].askPriceId,
                partId: list[i].partId,
                origin: [fom],
              });
              tempArr.push(list[i].partName);
            } else {
              for (let j = 0; j < that.afterData.length; j++) {
                if (that.afterData[j].name == list[i].partName) {
                  var foms = {
                    id: list[i].id,
                    gid: list[i].gid,
                    classify: list[i].classify
                      ? list[i].classify
                      : list[i].partType == 0
                        ? "原厂"
                        : "品牌",
                    price: list[i].price,
                    des: list[i].des,
                  };
                  that.afterData[j].origin.push(foms);
                  break;
                }
              }
            }
          }
        })
        .catch(() => loading.clear());
    },
    getshangjia() {
      var _this = this;
      askPriceList({ oid: this.oid, gid: this.gid }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let listArr = [];
          if (res.data.length == 0) {
            return;
          }
          res.data.map((v, i) => {
            var data = v.askPricePartDOList;
            data.map(function (el, index) {
              if (el.status == 0 || el.status == 1) {
                el.checked = true;
              } else {
                el.checked = false;
              }

              if (el.status == 0 || el.status == 1) {
                if (el.partName == v.partName) {
                  _this.$set(v, "status2", v.partName);
                }
              }
              if (el.arrivalTime) {
                el.arrivalTime = _this.DateDiffer(
                  el.arrivalTime,
                  _this.createdDate
                );
              }
              for (var i = 0; i < listArr.length; i++) {
                if (listArr[i].supplierName == el.supplierName) {
                  listArr[i].askPricePartDOS.push(el);
                  return;
                }
              }
              // 第一次对比没有参照，放入参照
              listArr.push({
                supplierName: el.supplierName,
                supplierPhone: el.supplierPhone,
                supplierLinkman: el.supplierLinkman,
                address: el.address,
                askPriceTime: el.askPriceTime,
                billNumber: el.billNumber,
                carNo: el.carNo,
                partId: el.partId,
                partName: el.partName,
                partTotalCount: el.partTotalCount,
                unPricedCount: el.unPricedCount,

                askPricePartDOS: [el],
              });
            });
          });
          listArr.map((v, i) => {
            v.list = this.sortArr(v.askPricePartDOS, "partId");
          });
          this.sjlist = listArr;
          console.log(listArr);
        }
      });
    },
    sortArr(arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function (a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length) {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t); //获取到所有整合数据
      console.log(_arr); //可以得到图三
      // 终极整合
      var integration = [];
      _arr.forEach((item) => {
        var eee = { name: "", disabled: false, data: [] };
        item.forEach((self) => {
          if (eee.name != self.payType) {
            if (self.status == 0 || self.status == 1) {
              eee.disabled = true;
            }
            eee.name = self.partName;
            eee.data.push(self);
          } else {
            eee.data.push(self.money);
          }
        });
        integration.push(eee);
      });
      console.log(integration); //图二
      return integration;
    },
    setcheckbox(items, it) {
      console.log(items);
      // if(it.)
      // for(var i in items.data){
      //   if(it.id != items.data[i].id){
      //     items.data[i].checked = false
      //   }
      // }
      if (it.checked) {
        this.askPricePartIds.push(it.id);
      } else {
        this.askPricePartIds.forEach((item, idx) => {
          if (it.id == item) {
            this.askPricePartIds.splice(idx, 1);
          }
        });
      }
      this.supplierId = it.supplierId;
      this.$forceUpdate();
      console.log(this.supplierId);
      console.log(this.askPricePartIds);
    },
    back() {
      this.$router.go(-1);
    },

  },
  created() {
    this.id = this.$route.query.id;
    this.gid = this.$route.query.gid;
    this.oid = this.$route.query.oid;
    // localStorage.setItem("Authorization", 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MTA1MSIsImV4cCI6MTY4MDIzMzk5M30.u2mS78EduF3AQCt7WVaVEDZ2ZJVpDLsHS8-IGOJCHZJlxjdxteEevcANJuO355SVpz0yS1QXzhJKcshORgorow');
    var url =
      this.$route.path +
      "?gid=" +
      this.gid +
      "&id=" +
      this.id +
      "&oid=" +
      this.oid +
      "&denglu=" +
      true;

    this.onLoad();



  },
  mounted() {

    var _this = this;
    document.addEventListener("click", (e) => {
      console.log(_this.ids);
      // 记得在.select-box那边加上ref="selectBox"
      if (_this.ids != null) {
        _this.ids = null;
      }
    });
  },
  computed: {},
};
</script>

<style>
[v-cloak] {
  display: none;
}

/*prettier-ignore */
html {
  font-size: 40PX !important;
}
</style>
<style lang="less" scoped>
.botsdb {
  border-radius: 12px 12px 0 0;
}
  .xjleibiao{
    line-height: 35px;
    font-size: 14px;
    padding:  0 12px;
    font-weight: bold;
    color: #000;
    border-bottom: 4px solid #f5f5f5;
  }
.zhe {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
}

.van-picker__cancel {
  font-size: 16px;
}

.boost {
  margin: 10px 0;
  align-items: center;

  .ipus {
    font-size: 16px;
    padding: 2px 12px;
    border: 1px solid #dcdcdc;
    // background-color: #f7f8fa;
  }
}

.vanbor {
  height: 100%;
  padding: 0 16px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #576b95;
}

//---配件商家模式
.beijins {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);

  img {
    width: 100%;
  }
}

.tisdiv {
  padding-left: 6px;
  line-height: 10px;
}

.tis {
  color: red;
  // padding-left: 12px;

  transform: scale(0.85);
  display: inline-block;
}

.pjmian {
  overflow: hidden;
  font-size: 12px;
  color: #666;

  .mian_a {
    display: flex;
    align-items: center;
    line-height: 30px;
    padding: 0 12px;
    justify-content: space-between;
    background: rgb(238, 252, 241);
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }

  .mian_b {
    display: flex;
    align-items: center;
    line-height: 30px;
    padding: 0 12px;
    justify-content: space-between;
    border-bottom: 4px solid #f5f5f5;
  }

  .mian_c {
    padding: 0 12px;
    border-bottom: 4px solid #f5f5f5;

    .pimin {
      line-height: 30px;
      border-bottom: 1px solid #f5f5f5;
      color: #000;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .pjdiv {
      padding: 5px 0 6px 0;
    }

    .flix {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 30px;
    }
  }
}

.jintan {
  width: 270px;
  border-radius: 4px;
  padding: 20px 0 0 0;
  text-align: center;
  font-size: 18px;

  .flxx {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    font-size: 16px;
  }

  .ipos {
    width: 120px;
    height: 32px;
    color: red;
    text-align: center;
  }

  .flss {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .van-button {
      flex: 1;
      height: 38px;
    }
  }
}

.red {
  color: red;
}

.hui {
  color: #666;
}

//----------------------------------------
.hestou {
  padding: 46px 0 0 0;
  // height: 100%;
  background: #fff;
  font-size: 13px;
  color: #333;

  .cardiv {
    line-height: 40px;
    padding: 0 16px;
    font-size: 13px;
  }

  .cjh {
    line-height: 35px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-top: 10px;

    .ldr {
      display: flex;
      width: calc(100% - 28px);

      .cnm {
        width: 80px;
      }

      .bar {
        flex: 1;
        background: #f9f9f9;
        width: 196px;
        margin-right: 10px;
        padding: 0 12px;
        text-align: right;
      }
    }
  }

  .che_img {
    width: 24px;
    height: 24px;
  }

  .cheimgs {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .bot {
    border-top: 4px solid #f6f6f6;
    padding-bottom: 10px;
    color: #000;
  }

  .flxsxs {
    display: flex;
    align-items: center;
    padding: 0px 15px 4px 15px;
    margin-top: 8px;

    .ipss {
      background: #f5f5f5;
      border: none;
      padding: 0 10px;
      flex: 1;
    }

    .bz {
      padding: 0 12px;
    }
  }

  .el-select {
    width: 1px;
    height: 1px;
  }

  .flxs {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 4px 15px;
    margin-top: 5px;

    .fxs {
      display: flex;
      align-items: center;
    }

    .shuzi {
      color: red;
      border: 1px solid red;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      // display: inline-block;
    }

    .shuliang {
      background: #f5f5f5;
      padding: 0 12px;
    }

    .ipss {
      width: 120px;
      background: #f5f5f5;
      border: none;
      padding: 0 10px;
    }
  }

  .fdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    // margin-top: 10px;
  }

  .ycdiv {
    width: 130px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 0px 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inpuss {
      background: #fff;
      border: none;
      width: 90px;
    }

    .divico {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
    }
  }

  ul {
    position: absolute;
    top: 30px;
    left: 0;
    width: 130px;
    margin-left: 15px;
    // margin: 0;
    padding: 0;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #e4e7ed;
    overflow-y: auto;
    height: 120px;
    z-index: 99;

    li {
      list-style: none;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      border-bottom: solid 1px #f1f1f1;
      background: #ffffff;
      color: #606266;
      padding: 0 12px;
    }

    li:last-child {
      border-bottom: none;
    }

    li:hover {
      background: #f6f6f6;
    }
  }

  ul::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  ul::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(144, 147, 153, .3);
  }

  ul::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    background: #ededed;
  }

  .lists {
    border-bottom: 2px solid #f5f5f5;
    position: relative;

    .tishi {
      background: #f1f1f1;
      box-shadow: 0 2px 12px rgba(50, 50, 51, 0.28);

      color: #333;
      position: absolute;
      top: 0;
      margin-top: 17px;
      font-size: 16px;
      left: 10px;
      transform-origin: center bottom;
      z-index: 2003;
      padding: 10px 20px;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    // .san {
    //   position: absolute;
    //   display: block;
    //   width: 0;
    //   height: 0;
    //   bottom: 0;
    //   margin-bottom: -28px;
    //   border-color: transparent;
    //   border-style: solid;

    //   border-top-color: #f9f9f9;
    //   border-bottom-width: 0;
    //   border-width: 16px;
    //   border: 1px solid #000;
    // }
  }

  .tishi :before {
    box-sizing: content-box;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -20px;
    left: 25px;
    padding: 0;
    border-top: 10px solid #f1f1f1;
    border-bottom: 10px solid transparent;
    border-left: 5px solid transparent;
    border-right: 20px solid transparent;
    display: block;
    content: "";
    z-index: 12;
  }

  .tishi :after {
    box-sizing: content-box;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -22px;
    left: 24px;
    padding: 0;
    border-top: 11px solid #f1f1f1;
    border-bottom: 11px solid transparent;
    border-left: 6px solid transparent;
    border-right: 20px solid transparent;
    display: block;
    content: "";
    z-index: 10;
  }

  .flximg {
    padding: 10px 15px;

    img {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
  }
}

.botti {
  width: 100%;
  position: fixed;
  bottom: 0;
}
</style>
<style>
.van-field__control {
  background-color: #f5f5f5 !important;
  width: 80%;
  height: 29px;
  padding: 0 12px;
}
</style>

